<template>
  <th-wrapper class="m-8" :title="$t('common.titles.general_info.title')">
    <el-form ref="form" v-loading="loading" :model="form" :rules="rules">
      <el-row :gutter="20">
        <!-- Name -->
        <el-col :sm="12" :md="12" :lg="6">
          <el-form-item
            :label="$t('pages.safe_management.edit.form.properties.name.label')"
            prop="name"
          >
            <el-input v-model="form.name" />
          </el-form-item>
        </el-col>

        <!-- Type -->
        <el-col :sm="12" :md="12" :lg="6">
          <el-form-item
            :label="
              $t('pages.safe_management.edit.form.properties.safe_type.label')
            "
            prop="type"
            required
          >
            <el-select
              v-model="form.type"
              class="w-full"
              :placeholder="
                $t(
                  'pages.safe_management.edit.form.properties.safe_type.placeholder'
                )
              "
            >
              <el-option
                v-for="item in availableSafeTypes"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>

        <!-- Cost center -->
        <el-col :sm="12" :md="12" :lg="4">
          <el-form-item
            :label="
              $t('pages.safe_management.edit.form.properties.cost_center.label')
            "
            prop="cost_center"
          >
            <el-input v-model="form.cost_center" />
          </el-form-item>
        </el-col>

        <!-- Upper limit -->
        <el-col :sm="6" :md="6" :lg="4">
          <el-form-item
            :label="
              $t('pages.safe_management.edit.form.properties.limit_upper.label')
            "
            prop="limit_upper"
          >
            <th-currency-input
              v-model="form.limit_upper[0].amount"
              :currency="form.limit_upper[0].currency"
            />
          </el-form-item>
        </el-col>

        <!-- Upper limit Currency -->
        <el-col :sm="6" :md="6" :lg="4">
          <el-form-item
            v-if="showCurrencySelect"
            :label="$t('common.headers.currency.title')"
          >
            <th-currency-select
              v-model="form.limit_upper[0].currency"
              :allowed-currencies="currencies"
              @update:modelValue="
                (currency) => updateCurrency(currency, 'limit_upper')
              "
            />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <!-- Account number -->
        <el-col :sm="12" :md="12" :lg="6">
          <el-form-item
            :label="
              $t(
                'pages.safe_management.edit.form.properties.account_number.label'
              )
            "
            prop="account_number"
          >
            <el-input
              v-model="form.account_number"
              :disabled="!isNew && isDatevEnabled && isDatevStrictMode"
            />
          </el-form-item>
        </el-col>

        <!-- Custom ID -->
        <el-col :sm="12" :md="12" :lg="6">
          <el-form-item
            :label="
              $t('pages.safe_management.edit.form.properties.custom_id.label')
            "
            prop="custom_id"
          >
            <el-input v-model="form.custom_id" />
          </el-form-item>
        </el-col>

        <!-- Location -->
        <el-col :sm="12" :md="12" :lg="4">
          <el-form-item
            :label="
              $t('pages.safe_management.edit.form.properties.location.label')
            "
            prop="location"
          >
            <single-location-select
              id="location"
              v-model="form.location"
              :filter-locations="(location) => location.type !== 'warehouse'"
            />
          </el-form-item>
        </el-col>

        <!-- Lower limit -->
        <el-col :sm="6" :md="6" :lg="4">
          <el-form-item
            :label="
              $t('pages.safe_management.edit.form.properties.limit_lower.label')
            "
            prop="limit_lower"
          >
            <th-currency-input
              v-model="form.limit_lower[0].amount"
              :currency="form.limit_lower[0].currency"
            />
          </el-form-item>
        </el-col>

        <!-- Currency -->
        <el-col :sm="6" :md="6" :lg="4">
          <el-form-item
            v-if="showCurrencySelect"
            :label="$t('common.headers.currency.title')"
          >
            <th-currency-select
              v-model="form.limit_lower[0].currency"
              :allowed-currencies="currencies"
              @update:modelValue="
                (currency) => updateCurrency(currency, 'limit_lower')
              "
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </th-wrapper>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import { mapGetters } from 'vuex'
import safeGet from 'just-safe-get'
import pick from 'just-pick'
import { isEmptyObject, isEmptyArray } from '@/utils/objects'
import { isEmptyString } from '@/utils/strings'
import SingleLocationSelect from '@/components/select/single-location-select'

const limitTypes = ['limit_upper', 'limit_lower']

function makeHandleableBody(payload, form) {
  const body = pick(
    payload,
    Object.keys(form).filter((item) => ![].includes(item))
  )

  // remove all invalid limit_upper/limit_lower objects
  // and if no valid object is left, replace array with null
  limitTypes.forEach((type) => {
    body[type] = body[type].filter((limit) => Number.isFinite(limit.amount))
    if (!body[type].length) body[type] = null
  })

  // nullify empty arrays, empty objects, and falsey values except zero
  Object.keys(body).forEach((key) => {
    if (
      isEmptyObject(body[key]) ||
      isEmptyArray(body[key]) ||
      isEmptyString(body[key]) ||
      (!body[key] && body[key] !== 0)
    )
      body[key] = null
  })

  return body
}

export default {
  name: 'SafeForm',
  components: {
    SingleLocationSelect
  },
  props: {
    visible: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      defaultCurrency: this.$store.getters['Config/getCurrentDefaultCurrency'],
      loading: false,
      valid: false,
      form: {
        name: null,
        account_number: null,
        type: 'safe',
        custom_id: null,
        cost_center: null,
        location: null,
        limit_upper: [
          {
            amount: null,
            currency: this.$store.getters['Config/getCurrentDefaultCurrency']
          }
        ],
        limit_lower: [
          {
            amount: null,
            currency: this.$store.getters['Config/getCurrentDefaultCurrency']
          }
        ]
      },
      availableSafeTypes: [
        {
          label: this.$t('pages.safe_management.edit.form.safe_types.safe'),
          value: 'safe'
        },
        {
          label: this.$t('pages.safe_management.edit.form.safe_types.vault'),
          value: 'vault'
        }
      ],
      rules: {
        name: [
          {
            required: true,
            message: this.$t(
              'pages.safe_management.edit.form.rules.name.required'
            ),
            trigger: 'blur'
          }
        ],
        account_number: [
          {
            required: true,
            message: this.$t(
              'pages.safe_management.edit.form.rules.account_number.required'
            ),
            trigger: 'blur'
          },
          {
            min: 1,
            max: 20,
            message: this.$t('common.forms.rules.min_max_length', {
              min: 1,
              max: 20
            }),
            trigger: 'blur'
          }
        ],
        type: [
          {
            required: true,
            message: this.$t(
              'pages.safe_management.edit.form.rules.type.required'
            ),
            trigger: 'blur'
          },
          {
            min: 1,
            max: 20,
            message: this.$t('common.forms.rules.min_max_length', {
              min: 1,
              max: 20
            }),
            trigger: 'blur'
          }
        ],
        custom_id: [
          {
            min: 1,
            max: 255,
            message: this.$t('common.forms.rules.min_max_length', {
              min: 1,
              max: 255
            }),
            trigger: 'blur'
          }
        ],
        cost_center: [
          {
            min: 1,
            max: 255,
            message: this.$t('common.forms.rules.min_max_length', {
              min: 1,
              max: 255
            }),
            trigger: 'blur'
          }
        ],
        location: [
          {
            required: true,
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: 'blur'
          }
        ]
      },
      payload: {}
    }
  },
  computed: {
    ...mapGetters({
      navigationAfterCreation: 'Config/getNavigationAfterCreation',
      currencies: 'Config/getAvailableCurrencies'
    }),
    isNew() {
      return [null, undefined, 'new'].includes(this.$route.params.id)
    },
    safeIsEmpty() {
      return (
        !this.payload ||
        !this.payload.items ||
        !this.payload.items.length ||
        this.payload.items.every((currency) => !currency.amount)
      )
    },
    isDatevEnabled() {
      return this.$isFeatureEnabled('datev')
    },
    isDatevStrictMode() {
      const strictMode = safeGet(
        this.$store.state.Config.clientAccountConfiguration,
        ['datev', 'strict_mode']
      )
      return typeof strictMode === 'boolean' ? strictMode : true
    },
    showCurrencySelect() {
      return this.currencies.length > 1
    }
  },
  async mounted() {
    if (!this.isNew) this.fetch(this.$route.params.id)
  },
  methods: {
    async fetch(id) {
      try {
        const inst = th.safes()

        this.loading = true

        const { data = {} } = await inst.get(id)

        if (data.id) {
          this.handleItem(data)
        }

        if (this.safeIsEmpty) this.$emit('safe-is-empty')

        this.loading = false
      } catch (err) {
        this.loading = false

        this.$message({
          type: 'error',
          message: this.$t('common.error.action.read.single', {
            resource: this.$t('common.resource.safe.singular')
          })
        })
      }
    },
    handleItem(item) {
      this.payload = this.$deepClone(item)
      this.form = {
        ...pick(item, Object.keys(this.form))
      }

      // initialize limit_upper and limit_lower with a default object
      limitTypes.forEach((type) => {
        if (!this.form[type])
          this.form[type] = [{ amount: null, currency: this.defaultCurrency }]
      })
    },
    submitForm(formName) {
      this.validate('form', (valid) => {
        if (!valid) {
          return this.$notify.warning({
            title: this.$t(
              'pages.safe_management.edit.form.messages.validation_fail.title'
            ),
            message: this.$t(
              'pages.safe_management.edit.form.messages.validation_fail.message'
            )
          })
        }

        if (this.isNew) return this.create()
        this.alter(this.payload.id)
      })
    },

    validate(formName = 'form', cb) {
      this.$refs[formName].validate((valid) => {
        return cb(valid)
      })
    },
    async alter(id) {
      const payload = {
        ...this.payload,
        ...this.form
      }

      const shouldWarnOnDatevChange =
        !this.isNew &&
        this.isDatevEnabled &&
        this.isDatevStrictMode === false &&
        this.payload.account_number !== this.form.account_number

      if (shouldWarnOnDatevChange) {
        const objectName = this.$t(
          'pages.safe_management.edit.form.properties.account_number.label'
        )
        try {
          await this.$confirm(
            this.$t('common.datev.related_change.warning.message', {
              objectName
            }),
            this.$t('common.titles.warning'),
            {
              confirmButtonText: this.$t('common.interactions.buttons.ok'),
              cancelButtonText: this.$t('common.interactions.buttons.cancel'),
              type: 'warning'
            }
          )
        } catch (err) {
          // no-op
          return
        }
      }

      try {
        const inst = th.safes()
        this.loading = true

        const { data = {} } = await inst.put(
          payload.id,
          makeHandleableBody(payload, this.form)
        )

        if (data.id) {
          this.handleItem(data)
        }

        this.$message({
          type: 'success',
          message: this.$t('common.success.action.update.single', {
            resource: this.$t('common.resource.safe.singular')
          })
        })

        this.$emit('handled-item')
        this.$emit('altered-item')

        if (this.navigationAfterCreation === 'edit') {
          this.$router.push({
            name: 'accounting-safe-management-overview',
            params: { id: data.id }
          })
        } else {
          this.$router.push({ name: 'accounting-safe-management' })
        }
      } catch (err) {
        this.$message({
          type: 'error',
          message: this.$t('common.error.action.update.single', {
            resource: this.$t('common.resource.safe.singular')
          })
        })
      } finally {
        this.loading = false
      }
    },
    async create() {
      const payload = {
        ...this.form
      }

      const inst = th.safes()
      this.loading = true

      let data
      try {
        const response = await inst.create({
          ...makeHandleableBody(payload, this.form)
        })
        data = response && response.data

        this.$message({
          type: 'success',
          message: this.$t('common.success.action.create.single', {
            resource: this.$t('common.resource.safe.singular')
          })
        })

        if (data && data.id) {
          this.handleItem(data)
        }

        this.$emit('handled-item')

        if (this.navigationAfterCreation === 'edit') {
          this.$router.push({
            name: 'accounting-safe-management-overview',
            params: { id: data.id }
          })
        } else {
          this.$router.push({ name: 'accounting-safe-management' })
        }
      } catch (err) {
        return this.$message({
          type: 'error',
          message: this.$t('common.error.action.create.single', {
            resource: this.$t('common.resource.safe.singular')
          })
        })
      } finally {
        this.loading = false
      }
    },
    updateCurrency(currency, type) {
      this.form[type][0].currency = currency
    },
    async handleDelete() {
      if (!this.safeIsEmpty) return

      const payload = this.payload
      const confirm = await this.$askToDelete(
        payload.name || payload.account_number || payload.id
      )
      if (confirm) this.delete(payload)
    },
    async delete(payload) {
      const successMessage = this.$t('common.success.action.delete.single', {
        resource: this.$t('common.resource.safe.singular')
      })
      const errorMessage = this.$t('common.error.action.delete.single', {
        resource: this.$t('common.resource.safe.singular')
      })

      try {
        const inst = th.safes()
        await inst.put(payload.id, { deleted: true })
        this.$message({
          type: 'success',
          message: successMessage
        })

        this.$router.push({ name: 'accounting-safe-management' })
      } catch (err) {
        this.$logException(err, {
          type: 'error',
          message: errorMessage
        })
      }
    }
  }
}
</script>
