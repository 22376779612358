<template>
  <th-page-wrapper
    :actions="actions"
    @delete="handleDelete"
    @save="handleSubmit"
  >
    <resource-form ref="form" @safe-is-empty="safeIsEmpty = true" />
  </th-page-wrapper>
</template>

<script>
import ResourceForm from './components/safe-form'

export default {
  name: 'SafeEdit',
  components: {
    ResourceForm
  },
  metaInfo() {
    return {
      title: this.$t('common.resource.safe.singular')
    }
  },
  data() {
    return {
      safeIsEmpty: false
    }
  },
  computed: {
    isNew() {
      return [null, undefined, 'new'].includes(this.$route.params.id)
    },
    safeId() {
      return this.$route.params.id
    },
    actions() {
      return {
        permissionPrefix: 'accounting:safe_management',
        returnedPath: { name: 'accounting-safe-management' },
        isNew: this.isNew,
        deleteTooltip: this.$t(
          'pages.safe_management.edit.form.delete_button.tooltip'
        ),
        deleteDisabled: !this.safeIsEmpty
      }
    }
  },
  methods: {
    handleSubmit() {
      this.$refs.form.submitForm()
    },
    handleDelete() {
      if (!this.safeIsEmpty) return
      this.$refs.form.handleDelete()
    }
  }
}
</script>
